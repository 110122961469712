import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import SpinLoading from '../components/SpinLoading'
import dayjs from 'dayjs'
import { useMoralis } from '../hooks/moralis'
import { NFTInfoType } from '../hooks/type'
import avatar from '../images/common/avater.png'
import { marketContract } from '../hooks/config'
type NFTCardProps = {
  width?: string
  height?: string
  imgHeight?: string
  NFTInfo: NFTInfoType
  showToSell?: boolean
  onToSell?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * @param {object} NFTInfo: NFT item Info
 * @returns
 */
const NFTCard: React.FC<NFTCardProps> = ({
  NFTInfo,
  width = '345px',
  height = '380px',
  imgHeight = '213px',
  showToSell = false,
  onToSell,
  onClick,
}) => {
  const { Moralis, syncMetadata } = useMoralis()
  const [loading, setLoading] = useState(true)
  const [metadata, setMetadata] = useState()
  useEffect(() => {
    setLoading(true)
    if (NFTInfo) {
      if (NFTInfo?.metadata) {
        setLoading(false)
        setMetadata(NFTInfo?.metadata)
      } else {
        if (NFTInfo?.token_uri) {
          fetch(NFTInfo.token_uri)
            .then(res => res.json())
            .then(result => {
              setMetadata(result)
              setLoading(false)
            })
            .catch(error => {
              syncMetadata?.({
                token_id: NFTInfo?.token_id,
                flag: 'metadata',
              }).then((res: any) => {
                console.log(res)
                setLoading(false)
              })
            })
        } else {
          syncMetadata?.({ token_id: NFTInfo?.token_id }).then((res: any) => {
            console.log(res)
            setLoading(false)
          })
        }
      }
    }
  }, [])

  return (
    <div
      className="bg-white rounded-lg overflow-hidden cursor-pointer shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_16px_24px_1px_rgba(0,0,0,0.1)] hover:-translate-x-1 hover:-translate-y-2 transition-all duration-300"
      style={{ width, height }}
    >
      {loading ? (
        <div className="flex w-full h-full">
          <SpinLoading className="w-auto h-auto m-auto" />
        </div>
      ) : (
        <div
          className="w-full h-full"
          onClick={e => {
            e.stopPropagation()
            onClick?.(e)
            navigate('/NFTDetails', { state: { NFTInfo } })
          }}
        >
          <div className="relative overflow-hidden">
            <img
              src={metadata?.fileURI}
              alt="NFTImg"
              className="h-[213px] object-cover w-full"
              style={{ height: imgHeight }}
            />
            {!showToSell ? (
              <div className="absolute w-[62px] h-7 bg-[rgba(255,255,255,0.8)] rounded-[18px] flex justify-center items-center top-[10px] right-[10px]">
                <div className="w-[6px] h-[6px] rounded-full bg-[#3AD29F]" />
                <span className="text-[#3AD29F] text-[12px] leading-6 ml-2">
                  In Sell
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="py-5 px-[25px] border-b border-[#F2F2F2]">
            <div className="flex items-center justify-between">
              <div className="text-[18px] leading-[30px] font-boldText font-bold">
                {metadata?.worksName}
              </div>
            </div>
            <div className="mt-3 text-[#828282] text-sm font-numberText">
              {dayjs(NFTInfo?.last_token_uri_sync)
                .locale('en')
                .format('ddd MMM D YYYY')}
            </div>
          </div>
          <div className="px-[25px] py-5 flex justify-between items-center">
            <div className="flex items-center">
              <img
                src={NFTInfo?.userInfo?.avatarUrl || avatar}
                alt="userAvatar"
                className="w-6 h-6 rounded-full"
              />
              <div className="ml-1 text-[#828282] text-sm">
                {NFTInfo?.userInfo?.username || 'anonymous'}
              </div>
            </div>
            {showToSell && (
              <button
                className="w-[60px] h-[30px] bg-[#1E94FC] rounded-[28px] text-[12px] leading-[18px] text-white"
                onClick={e => {
                  e.stopPropagation()
                  onToSell?.(e)
                }}
              >
                To sell
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default NFTCard
